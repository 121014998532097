.container {
    max-width: $container-width;
    margin: 0 auto;
    padding: 0 15px;
}

.box {
    background: $bg-color-1;
    border: 1px solid #CCCCCC;
    box-shadow: inset 0px 1px 0px 0px #ECECEC;
    border-radius: 3px;
    font-size: $font-size;
    color: #4A4A4A;
    box-sizing: border-box;
}

.inputbox {
    float: right;
    height: 32px;
    width: 75%;
    padding: 0 10px;
    margin: 0 5px;
}

.textbox {
    width: 100%;
    height: 260px;
    padding: 5px 10px;
    margin: 0 5px 10px;
}

.btn {
    border-radius: 3px;
    width: 70px;
    height: 32px;
    border: none;
    font-size: $font-size;
    color: $text-color-1;
    margin: 0 auto;
}

.submit {
    background: #fff066;
}

.submit:hover {
    background: $text-color-3;
}

.submit:focus {
    background: $text-color-2;
}

.reset {
    background: rgba(0, 0, 0, 0.35);
}

.reset:hover {
    background: rgba(0, 0, 0, 0.2);
}

.reset:focus {
    background: rgba(0, 0, 0, 0.5);
}

h1 {
    color: $text-color-1;
    font-size: $font-size+20;
    line-height: $line-height;
}

h2 {
    font-size: $font-size+14;
    color: $text-color-3;
    line-height: $line-height+0.2;
}

h3 {
    font-size: $font-size+8;
    color: $text-color-3;
    line-height: $line-height+0.2;
}

h4 {
    font-size: $font-size+4;
    color: $text-color-1;
    line-height: $line-height+0.5;
}

h5 {
    font-size: $font-size+2;
    color: $text-color-2;
    line-height: $line-height+0.3;
}

h6 {
    font-size: $font-size;
    color: #EFE9E7;
    line-height: $line-height+0.2;
}

p {
    font-size: $font-size;
    color: $text-color-1;
    line-height: $line-height+0.3;
}

.active {
    color: rgba(111, 111, 111, 0.5);
}

.animated {
    opacity: 0;
    transition: all 2s;
    transform: translateY(150px);
}

.dranimated {
    opacity: 0;
    transition: all 5s;
    transform: translateX(200px)
}

.dr2animated {
    opacity: 0;
    transition: all 5s;
    transform: translateX(-200px)
}

.fadeIn {
    opacity: 1;
    transform: translateY(0);
}

.bg {
    background: $bg-color-2;
}
@mixin pc {
    @media (max-width: 1280px) {
        @content;
    }
}

@mixin pc2 {
    @media (max-width: 1079px) {
        @content;
    }
}

@mixin pc3 {
    @media (max-width: 890px) {
        @content;
    }
}

@mixin pad {
    @media (max-width: 639px) {
        @content;
    }
}

@mixin pad2 {
    @media (max-width: 736px) {
        @content;
    }
}

@mixin phone {
    @media (max-width: 440px) {
        @content;
    }
}
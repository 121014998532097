    .header {
        .round1 {
            position: absolute;
            float: right;
            top: -350px;
            right: -200px;
            height: 1000px;
            width: 1000px;
            background-color: $bg-color-2;
            border-radius: 1000px;
        }
        .logo2 {
            display: block;
            float: left;
            width: 200px;
            padding: 2px 15px 3px 15px;
            margin: 10px 0;
        }
        .nav {
            position: fixed;
            z-index: 120;
            width: 100%;
            font-size: $font-size;
            color: $text-color-1;
            text-align: center;
            ul {
                float: right;
            }
            li {
                float: left;
                padding: 2px 15px 3px 15px;
                margin: 18px 0 17px 0;
                a:hover,
                a:active {
                    color: rgba(111, 111, 111, 0.5);
                }
            }
            .showmenu {
                display: none;
            }
        }
        .logo {
            position: absolute;
            top: 350px;
            // left: 140px;
            float: left;
            width: 50%;
            text-align: center;
            text-shadow: -2px 2px 0px rgba(0, 0, 0, 0.16);
            z-index: 10;
        }
        .dragon {
            position: absolute;
            float: left;
            top: 30px;
            right: 0px;
            height: 686px;
            width: 654px;
            background: url('../images/jaydragon.png');
        }
        .slide {
            margin: 0 auto;
            clear: both;
            position: relative;
            top: 700px;
            height: 29px;
            width: 81px;
            img {
                transition: all 0.1s;
            }
            img:hover {
                position: relative;
                top: 10px;
            }
        }
    }
    
    .nav-logo {
        display: none;
    }
    
    .bgbox1 {
        position: absolute;
        top: 850px;
        left: -100px;
        height: 800px;
        width: 800px;
        background-color: $bg-color-2;
        transform: rotate(45deg);
    }
    
    .bgbox2 {
        position: absolute;
        top: 1050px;
        right: -80px;
        height: 1100px;
        width: 1100px;
        background-color: #ffcc33;
        transform: rotate(45deg);
    }
    
    .sline {
        position: relative;
        top: 800px;
        margin: 0 auto;
        clear: both;
        height: 1164px;
        width: 7px;
        background: #cc9966;
    }
    
    .hline {
        position: relative;
        margin: 0 auto;
        height: 3px;
        width: 130px;
        background: #cc9966;
    }
    
    .hline1 {
        top: -200px;
    }
    
    .hline4 {
        top: -400px;
    }
    
    .hline2,
    .hline3 {
        top: -300px;
    }
    
    .hline1,
    .hline3 {
        left: -65px;
    }
    
    .card {
        height: 250px;
        width: 500px;
        padding: 15px;
        img {
            height: 220px;
        }
        h2 {
            width: 250px;
            margin: 0 15px 0;
            padding: 10px 0 0 0;
        }
        ul {
            width: 200px;
            margin: 15px;
        }
    }
    
    .service1 {
        top: -320px;
    }
    
    .service4 {
        top: -550px;
    }
    
    .service2,
    .service3 {
        top: -450px;
    }
    
    .service1,
    .service3 {
        position: relative;
        margin: 0 auto;
        left: -380px;
        img {
            float: right;
        }
        h2,
        ul {
            float: right;
            text-align: right;
        }
    }
    
    .hline2,
    .hline4 {
        left: 65px;
    }
    
    .service2,
    .service4 {
        position: relative;
        margin: 0 auto;
        left: 380px;
        img {
            float: left;
        }
        h2,
        ul {
            float: left;
            text-align: left;
        }
    }
    
    .bgbox3 {
        position: absolute;
        left: 70px;
        top: 2100px;
        height: 1440px;
        width: 1440px;
        background-color: $text-color-2;
        transform: rotate(45deg);
    }
    
    .about {
        position: absolute;
        top: 2000px;
        .dragon2 {
            float: left;
            height: 490px;
            width: 685px;
            background: url('../images/red.png');
        }
        .text {
            float: left;
            width: 30%;
            padding: 20px;
            margin: 200px auto;
            h1 {
                padding: 20px 0;
            }
        }
    }
    
    .case {
        position: absolute;
        top: 2600px;
        clear: both;
        width: 100%;
    }
    
    .poto {
        float: left;
        position: relative;
        z-index: 55;
        width: 12.5%;
        transition: transform .3s .3s, z-index .5s step-end;
        &:hover {
            position: relative;
            z-index: 100;
            transform: scale(2);
            transition: transform .2s, z-index .3s step-start;
        }
    }
    
    .connection {
        clear: both;
        margin: 0 auto;
        position: relative;
        top: 1000px;
        h1 {
            text-align: center;
            padding: 10px;
            margin: 0 0 30px 0;
        }
        h4 {
            padding: 10px;
        }
        .form1 {
            text-align: right;
            float: left;
            width: 45%;
            margin: 0 2.5%;
        }
        .form2 {
            float: left;
            width: 45%;
            margin: 0 2.5% 20px;
        }
        .btng {
            clear: both;
            padding: 20px 42%;
        }
    }
    
    .footer {
        background: $bg-color-2;
        margin: 50px auto 0;
        position: relative;
        bottom: -1000px;
        .container {
            padding: 40px;
            .context {
                float: left;
            }
            .flogo {
                float: right;
            }
            p {
                clear: both;
            }
        }
    }
    
    @include pc {
        .hline {
            width: 80px;
        }
        .hline1,
        .hline3 {
            left: -38px;
        }
        .hline2,
        .hline4 {
            left: 38px;
        }
        .card {
            width: 500px;
            padding: 15px;
            img {
                height: 180px;
            }
            h2 {
                margin: 0 15px 0;
                padding: 10px 0 0 0;
            }
            ul {
                margin: 15px;
            }
        }
        .service4 {
            top: -500px;
        }
        .service2,
        .service3 {
            top: -430px;
        }
        .service1,
        .service3 {
            left: -350px;
        }
        .service2,
        .service4 {
            left: 350px;
        }
        .about {
            .text {
                padding: 10px;
                h1 {
                    padding: 10px 0;
                }
            }
        }
        .connection {
            top: 800px;
        }
        .footer {
            margin: 0 auto;
            bottom: -880px;
        }
    }
    
    @include pc2 {
        .header {
            .dragon {
                float: right;
                right: -100px;
                height: 550px;
                background: url('../images/jaydragon.png') no-repeat;
                background-size: contain;
            }
        }
        .card {
            width: 250px;
            padding: 15px;
            text-align: center;
            img {
                max-width: 100%;
                text-align: center;
            }
            h2 {
                width: 100%;
                padding: 10px 0 0 0;
            }
            ul {
                width: 100%;
                margin: 15px;
            }
        }
        .service4 {
            top: -500px;
        }
        .service2,
        .service3 {
            top: -430px;
        }
        .service1,
        .service3 {
            left: -200px;
            img {
                float: none;
            }
            h2,
            ul {
                float: none;
                text-align: center;
            }
        }
        .service2,
        .service4 {
            left: 200px;
            img {
                float: none;
            }
            h2,
            ul {
                float: none;
                text-align: center;
            }
            z-index: 10;
        }
        .about {
            .dragon2 {
                float: left;
                width: 60%;
                background: url('../images/red.png') no-repeat;
                background-size: cover;
            }
            .text {
                padding: 10px;
                h1 {
                    padding: 10px 0;
                }
            }
        }
        .connection {
            top: 750px;
            .btng {
                padding: 20px 40%;
            }
        }
        .footer {
            margin: 0 auto;
            bottom: -880px;
        }
    }
    
    @include pc3 {
        .header {
            .logo2 {
                width: 150px;
            }
            .dragon {
                right: -200px;
                height: 450px;
                clear: both;
            }
            .logo {
                top: 300px;
            }
            .slide {
                top: 600px;
                z-index: 10;
            }
        }
        .service {
            margin: 0 auto;
            width: 100%;
        }
        .hline,
        .sline {
            display: none;
        }
        .card {
            width: 45%;
            padding: 1%;
        }
        .service1 {
            float: left;
            top: 700px;
        }
        .service2 {
            float: right;
            top: 700px;
        }
        .service3 {
            float: left;
            top: 900px;
        }
        .service4 {
            float: right;
            top: 900px;
        }
        .service1,
        .service3 {
            left: 0px;
        }
        .service2,
        .service4 {
            left: 0px;
        }
        .about {
            top: 1600px;
            clear: both;
            .dragon2 {
                float: left;
                width: 40%;
                background: url('../images/red.png') no-repeat;
                background-size: contain;
            }
            .text {
                width: 55%;
                padding: 10px;
                margin: 0 auto;
                h1 {
                    padding: 10px 0;
                }
            }
        }
        .case {
            top: 1900px;
            clear: both;
        }
        .poto {
            width: 16.66%;
        }
        .connection {
            top: 2000px;
            clear: both;
            margin: 0 auto;
            position: relative;
            .form1 {
                text-align: right;
                float: left;
                width: 100%;
            }
            .form2 {
                float: left;
                width: 98%;
            }
            .btng {
                clear: both;
                margin: 0 auto;
                padding: 40px;
                text-align: center;
            }
        }
        .footer {
            margin: 0 auto;
            bottom: -2250px;
            position: relative;
            .container {
                padding: 60px;
                .context {
                    float: left;
                }
                .flogo {
                    float: left;
                }
                p {
                    clear: both;
                }
            }
        }
    }
    
    @include pad {
        .header {
            .dragon {
                right: -350px;
                top: 60px;
                height: 300px;
                clear: both;
            }
            .logo {
                top: 300px;
                width: 100%;
            }
            .slide {
                top: 600px;
                z-index: 10;
            }
            .nav {
                ul {
                    max-height: 0;
                    overflow: hidden;
                    position: absolute;
                    z-index: 100;
                    top: 55px;
                    right: 0;
                    left: 0;
                    background: #fff;
                }
                li {
                    float: none;
                }
                .showmenu {
                    display: block;
                    float: right;
                    padding: 2px 15px 3px 15px;
                    margin: 18px 0 17px 0;
                }
            }
        }
        body.navshow {
            .header {
                .nav {
                    ul {
                        max-height: 500px;
                    }
                }
            }
        }
        .card {
            width: 96%;
            padding: 2%;
        }
        .service1 {
            top: 700px;
        }
        .service2 {
            top: 850px;
        }
        .service3 {
            top: 980px;
        }
        .service4 {
            top: 1100px;
        }
        .service1,
        .service3 {
            left: 0px;
        }
        .service2,
        .service4 {
            left: 0px;
        }
        .about {
            top: 2400px;
            .dragon2 {
                display: none;
            }
            .text {
                width: 90%;
                padding: 5%;
                margin: 0 auto;
                h1 {
                    padding: 10px 0;
                }
            }
        }
        .case {
            top: 2700px;
            clear: both;
        }
        .poto {
            width: 25%;
            margin: 40px;
        }
        .connection {
            top: 2100px;
            clear: both;
            margin: 0 auto;
            position: relative;
            .form1 {
                text-align: right;
                float: left;
                width: 100%;
            }
            .form2 {
                float: left;
                width: 98%;
            }
            .btng {
                clear: both;
                margin: 0 auto;
                padding: 40px;
                text-align: center;
            }
        }
        .footer {
            margin: 0 auto;
            bottom: -2250px;
            position: relative;
            .container {
                padding: 60px;
                .context {
                    float: left;
                }
                .flogo {
                    float: left;
                }
                p {
                    clear: both;
                }
            }
        }
    }
    
    @include phone {
        .connection {
            top: 2000px;
            clear: both;
            margin: 0 auto;
            position: relative;
            .form1 {
                text-align: right;
                float: left;
                width: 100%;
                .inputbox {
                    width: 60%;
                }
            }
            .form2 {
                float: left;
                width: 98%;
            }
            .btng {
                clear: both;
                margin: 0 auto;
                padding: 40px;
                text-align: center;
            }
        }
        .footer {
            margin: 0 auto;
            bottom: -2250px;
            position: relative;
            .container {
                padding: 60px;
                .context {
                    float: left;
                }
                .flogo {
                    float: left;
                }
                p {
                    clear: both;
                }
            }
        }
    }
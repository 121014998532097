@charset "UTF-8";
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: "微軟正黑體", "Microsoft JhengHei"; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1;
  position: relative;
  overflow-x: hidden; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
body {
  height: 100%;
  background: #ffffff; }

img {
  max-width: 100%;
  height: auto; }

a {
  text-decoration: none;
  color: #000000; }

.header .round1 {
  position: absolute;
  float: right;
  top: -350px;
  right: -200px;
  height: 1000px;
  width: 1000px;
  background-color: #fae534;
  border-radius: 1000px; }

.header .logo2 {
  display: block;
  float: left;
  width: 200px;
  padding: 2px 15px 3px 15px;
  margin: 10px 0; }

.header .nav {
  position: fixed;
  z-index: 120;
  width: 100%;
  font-size: 16px;
  color: #000000;
  text-align: center; }
  .header .nav ul {
    float: right; }
  .header .nav li {
    float: left;
    padding: 2px 15px 3px 15px;
    margin: 18px 0 17px 0; }
    .header .nav li a:hover,
    .header .nav li a:active {
      color: rgba(111, 111, 111, 0.5); }
  .header .nav .showmenu {
    display: none; }

.header .logo {
  position: absolute;
  top: 350px;
  float: left;
  width: 50%;
  text-align: center;
  text-shadow: -2px 2px 0px rgba(0, 0, 0, 0.16);
  z-index: 10; }

.header .dragon {
  position: absolute;
  float: left;
  top: 30px;
  right: 0px;
  height: 686px;
  width: 654px;
  background: url("../images/jaydragon.png"); }

.header .slide {
  margin: 0 auto;
  clear: both;
  position: relative;
  top: 700px;
  height: 29px;
  width: 81px; }
  .header .slide img {
    transition: all 0.1s; }
  .header .slide img:hover {
    position: relative;
    top: 10px; }

.nav-logo {
  display: none; }

.bgbox1 {
  position: absolute;
  top: 850px;
  left: -100px;
  height: 800px;
  width: 800px;
  background-color: #fae534;
  transform: rotate(45deg); }

.bgbox2 {
  position: absolute;
  top: 1050px;
  right: -80px;
  height: 1100px;
  width: 1100px;
  background-color: #ffcc33;
  transform: rotate(45deg); }

.sline {
  position: relative;
  top: 800px;
  margin: 0 auto;
  clear: both;
  height: 1164px;
  width: 7px;
  background: #cc9966; }

.hline {
  position: relative;
  margin: 0 auto;
  height: 3px;
  width: 130px;
  background: #cc9966; }

.hline1 {
  top: -200px; }

.hline4 {
  top: -400px; }

.hline2,
.hline3 {
  top: -300px; }

.hline1,
.hline3 {
  left: -65px; }

.card {
  height: 250px;
  width: 500px;
  padding: 15px; }
  .card img {
    height: 220px; }
  .card h2 {
    width: 250px;
    margin: 0 15px 0;
    padding: 10px 0 0 0; }
  .card ul {
    width: 200px;
    margin: 15px; }

.service1 {
  top: -320px; }

.service4 {
  top: -550px; }

.service2,
.service3 {
  top: -450px; }

.service1,
.service3 {
  position: relative;
  margin: 0 auto;
  left: -380px; }
  .service1 img,
  .service3 img {
    float: right; }
  .service1 h2,
  .service1 ul,
  .service3 h2,
  .service3 ul {
    float: right;
    text-align: right; }

.hline2,
.hline4 {
  left: 65px; }

.service2,
.service4 {
  position: relative;
  margin: 0 auto;
  left: 380px; }
  .service2 img,
  .service4 img {
    float: left; }
  .service2 h2,
  .service2 ul,
  .service4 h2,
  .service4 ul {
    float: left;
    text-align: left; }

.bgbox3 {
  position: absolute;
  left: 70px;
  top: 2100px;
  height: 1440px;
  width: 1440px;
  background-color: #ffee00;
  transform: rotate(45deg); }

.about {
  position: absolute;
  top: 2000px; }
  .about .dragon2 {
    float: left;
    height: 490px;
    width: 685px;
    background: url("../images/red.png"); }
  .about .text {
    float: left;
    width: 30%;
    padding: 20px;
    margin: 200px auto; }
    .about .text h1 {
      padding: 20px 0; }

.case {
  position: absolute;
  top: 2600px;
  clear: both;
  width: 100%; }

.poto {
  float: left;
  position: relative;
  z-index: 55;
  width: 12.5%;
  transition: transform .3s .3s, z-index .5s step-end; }
  .poto:hover {
    position: relative;
    z-index: 100;
    transform: scale(2);
    transition: transform .2s, z-index .3s step-start; }

.connection {
  clear: both;
  margin: 0 auto;
  position: relative;
  top: 1000px; }
  .connection h1 {
    text-align: center;
    padding: 10px;
    margin: 0 0 30px 0; }
  .connection h4 {
    padding: 10px; }
  .connection .form1 {
    text-align: right;
    float: left;
    width: 45%;
    margin: 0 2.5%; }
  .connection .form2 {
    float: left;
    width: 45%;
    margin: 0 2.5% 20px; }
  .connection .btng {
    clear: both;
    padding: 20px 42%; }

.footer {
  background: #fae534;
  margin: 50px auto 0;
  position: relative;
  bottom: -1000px; }
  .footer .container {
    padding: 40px; }
    .footer .container .context {
      float: left; }
    .footer .container .flogo {
      float: right; }
    .footer .container p {
      clear: both; }

@media (max-width: 1280px) {
  .hline {
    width: 80px; }
  .hline1,
  .hline3 {
    left: -38px; }
  .hline2,
  .hline4 {
    left: 38px; }
  .card {
    width: 500px;
    padding: 15px; }
    .card img {
      height: 180px; }
    .card h2 {
      margin: 0 15px 0;
      padding: 10px 0 0 0; }
    .card ul {
      margin: 15px; }
  .service4 {
    top: -500px; }
  .service2,
  .service3 {
    top: -430px; }
  .service1,
  .service3 {
    left: -350px; }
  .service2,
  .service4 {
    left: 350px; }
  .about .text {
    padding: 10px; }
    .about .text h1 {
      padding: 10px 0; }
  .connection {
    top: 800px; }
  .footer {
    margin: 0 auto;
    bottom: -880px; } }

@media (max-width: 1079px) {
  .header .dragon {
    float: right;
    right: -100px;
    height: 550px;
    background: url("../images/jaydragon.png") no-repeat;
    background-size: contain; }
  .card {
    width: 250px;
    padding: 15px;
    text-align: center; }
    .card img {
      max-width: 100%;
      text-align: center; }
    .card h2 {
      width: 100%;
      padding: 10px 0 0 0; }
    .card ul {
      width: 100%;
      margin: 15px; }
  .service4 {
    top: -500px; }
  .service2,
  .service3 {
    top: -430px; }
  .service1,
  .service3 {
    left: -200px; }
    .service1 img,
    .service3 img {
      float: none; }
    .service1 h2,
    .service1 ul,
    .service3 h2,
    .service3 ul {
      float: none;
      text-align: center; }
  .service2,
  .service4 {
    left: 200px;
    z-index: 10; }
    .service2 img,
    .service4 img {
      float: none; }
    .service2 h2,
    .service2 ul,
    .service4 h2,
    .service4 ul {
      float: none;
      text-align: center; }
  .about .dragon2 {
    float: left;
    width: 60%;
    background: url("../images/red.png") no-repeat;
    background-size: cover; }
  .about .text {
    padding: 10px; }
    .about .text h1 {
      padding: 10px 0; }
  .connection {
    top: 750px; }
    .connection .btng {
      padding: 20px 40%; }
  .footer {
    margin: 0 auto;
    bottom: -880px; } }

@media (max-width: 890px) {
  .header .logo2 {
    width: 150px; }
  .header .dragon {
    right: -200px;
    height: 450px;
    clear: both; }
  .header .logo {
    top: 300px; }
  .header .slide {
    top: 600px;
    z-index: 10; }
  .service {
    margin: 0 auto;
    width: 100%; }
  .hline,
  .sline {
    display: none; }
  .card {
    width: 45%;
    padding: 1%; }
  .service1 {
    float: left;
    top: 700px; }
  .service2 {
    float: right;
    top: 700px; }
  .service3 {
    float: left;
    top: 900px; }
  .service4 {
    float: right;
    top: 900px; }
  .service1,
  .service3 {
    left: 0px; }
  .service2,
  .service4 {
    left: 0px; }
  .about {
    top: 1600px;
    clear: both; }
    .about .dragon2 {
      float: left;
      width: 40%;
      background: url("../images/red.png") no-repeat;
      background-size: contain; }
    .about .text {
      width: 55%;
      padding: 10px;
      margin: 0 auto; }
      .about .text h1 {
        padding: 10px 0; }
  .case {
    top: 1900px;
    clear: both; }
  .poto {
    width: 16.66%; }
  .connection {
    top: 2000px;
    clear: both;
    margin: 0 auto;
    position: relative; }
    .connection .form1 {
      text-align: right;
      float: left;
      width: 100%; }
    .connection .form2 {
      float: left;
      width: 98%; }
    .connection .btng {
      clear: both;
      margin: 0 auto;
      padding: 40px;
      text-align: center; }
  .footer {
    margin: 0 auto;
    bottom: -2250px;
    position: relative; }
    .footer .container {
      padding: 60px; }
      .footer .container .context {
        float: left; }
      .footer .container .flogo {
        float: left; }
      .footer .container p {
        clear: both; } }

@media (max-width: 639px) {
  .header .dragon {
    right: -350px;
    top: 60px;
    height: 300px;
    clear: both; }
  .header .logo {
    top: 300px;
    width: 100%; }
  .header .slide {
    top: 600px;
    z-index: 10; }
  .header .nav ul {
    max-height: 0;
    overflow: hidden;
    position: absolute;
    z-index: 100;
    top: 55px;
    right: 0;
    left: 0;
    background: #fff; }
  .header .nav li {
    float: none; }
  .header .nav .showmenu {
    display: block;
    float: right;
    padding: 2px 15px 3px 15px;
    margin: 18px 0 17px 0; }
  body.navshow .header .nav ul {
    max-height: 500px; }
  .card {
    width: 96%;
    padding: 2%; }
  .service1 {
    top: 700px; }
  .service2 {
    top: 850px; }
  .service3 {
    top: 980px; }
  .service4 {
    top: 1100px; }
  .service1,
  .service3 {
    left: 0px; }
  .service2,
  .service4 {
    left: 0px; }
  .about {
    top: 2400px; }
    .about .dragon2 {
      display: none; }
    .about .text {
      width: 90%;
      padding: 5%;
      margin: 0 auto; }
      .about .text h1 {
        padding: 10px 0; }
  .case {
    top: 2700px;
    clear: both; }
  .poto {
    width: 25%;
    margin: 40px; }
  .connection {
    top: 2100px;
    clear: both;
    margin: 0 auto;
    position: relative; }
    .connection .form1 {
      text-align: right;
      float: left;
      width: 100%; }
    .connection .form2 {
      float: left;
      width: 98%; }
    .connection .btng {
      clear: both;
      margin: 0 auto;
      padding: 40px;
      text-align: center; }
  .footer {
    margin: 0 auto;
    bottom: -2250px;
    position: relative; }
    .footer .container {
      padding: 60px; }
      .footer .container .context {
        float: left; }
      .footer .container .flogo {
        float: left; }
      .footer .container p {
        clear: both; } }

@media (max-width: 440px) {
  .connection {
    top: 2000px;
    clear: both;
    margin: 0 auto;
    position: relative; }
    .connection .form1 {
      text-align: right;
      float: left;
      width: 100%; }
      .connection .form1 .inputbox {
        width: 60%; }
    .connection .form2 {
      float: left;
      width: 98%; }
    .connection .btng {
      clear: both;
      margin: 0 auto;
      padding: 40px;
      text-align: center; }
  .footer {
    margin: 0 auto;
    bottom: -2250px;
    position: relative; }
    .footer .container {
      padding: 60px; }
      .footer .container .context {
        float: left; }
      .footer .container .flogo {
        float: left; }
      .footer .container p {
        clear: both; } }

.container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 15px; }

.box {
  background: #ffffff;
  border: 1px solid #CCCCCC;
  box-shadow: inset 0px 1px 0px 0px #ECECEC;
  border-radius: 3px;
  font-size: 16px;
  color: #4A4A4A;
  box-sizing: border-box; }

.inputbox {
  float: right;
  height: 32px;
  width: 75%;
  padding: 0 10px;
  margin: 0 5px; }

.textbox {
  width: 100%;
  height: 260px;
  padding: 5px 10px;
  margin: 0 5px 10px; }

.btn {
  border-radius: 3px;
  width: 70px;
  height: 32px;
  border: none;
  font-size: 16px;
  color: #000000;
  margin: 0 auto; }

.submit {
  background: #fff066; }

.submit:hover {
  background: #cc9966; }

.submit:focus {
  background: #ffee00; }

.reset {
  background: rgba(0, 0, 0, 0.35); }

.reset:hover {
  background: rgba(0, 0, 0, 0.2); }

.reset:focus {
  background: rgba(0, 0, 0, 0.5); }

h1 {
  color: #000000;
  font-size: 36px;
  line-height: 1.2em; }

h2 {
  font-size: 30px;
  color: #cc9966;
  line-height: 1.4em; }

h3 {
  font-size: 24px;
  color: #cc9966;
  line-height: 1.4em; }

h4 {
  font-size: 20px;
  color: #000000;
  line-height: 1.7em; }

h5 {
  font-size: 18px;
  color: #ffee00;
  line-height: 1.5em; }

h6 {
  font-size: 16px;
  color: #EFE9E7;
  line-height: 1.4em; }

p {
  font-size: 16px;
  color: #000000;
  line-height: 1.5em; }

.active {
  color: rgba(111, 111, 111, 0.5); }

.animated {
  opacity: 0;
  transition: all 2s;
  transform: translateY(150px); }

.dranimated {
  opacity: 0;
  transition: all 5s;
  transform: translateX(200px); }

.dr2animated {
  opacity: 0;
  transition: all 5s;
  transform: translateX(-200px); }

.fadeIn {
  opacity: 1;
  transform: translateY(0); }

.bg {
  background: #fae534; }

$text-color-1: #000000;
$text-color-2: #ffee00;
$text-color-3: #cc9966;
$bg-color-1: #ffffff;
$bg-color-2: rgba(250, 229, 52, 1);
$bg-color-3: #eee;
$font-size: 16px;
$line-height: 1.2em;
$font-style: "微軟正黑體",
"Microsoft JhengHei";
$container-width: 1024px;
$logo-bg-png: url('/img/logo_l.png')center right no-repeat;
$logo-bg-svg: url('../img/logo_l(null).svg')center right no-repeat;